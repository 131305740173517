import React from "react";
import { Link } from 'react-router-dom';
import './Home.css'

import facebook from '../../assets/facebook.svg'
import email from '../../assets/email.svg'
import instagram from '../../assets/instagram.svg'

const Home = ({ blogs }) => {
    return (
        <>
            <div className="home-section">
                <h1>PlaPer</h1>
                {/* <h2>Your ultimate guide to places</h2>
                <span>
                    <Link to="/" className="custom-link ">About Us</Link>&nbsp;&nbsp;&nbsp;
                    <Link to="/blogs" className="custom-link ">Blogs</Link>
                    &nbsp;&nbsp;&nbsp;
                    <Link to="/blogs" className="custom-link ">FAQ's</Link>
                </span>
                <br></br>
                <span>
                    <img src={facebook} alt="facebook-icon"></img>
                    <img src={email} alt="email-icon"></img>
                    <img src={instagram} alt="instagram-icon"></img>
                </span> */}



            </div>


        </>
    );
}

export default Home;