import React from 'react';
import { ModelPage } from './modelPage';


import CardWindow from './CardWindow';

// Components
import ListOfBlogs from './Components/Blogs/ListOfBlogs';
import Home from './Components/Home/Home';

import ViewBlog from './Components/Blogs/ViewBlog';

import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';




const App = () => {


  return (
    <>
      <Router>
        <Routes>
          <Route path="/blogs" element={<ListOfBlogs />} />
          <Route path="/blog/:id" element={<ViewBlog />} />
          <Route path="/" element={<Home />} />
          <Route path="/model" element={<ModelPage />} />

        </Routes>
      </Router>
    </>
  );
};

export default App;
