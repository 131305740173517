import React, { useRef, useState } from 'react';
import { Canvas, useFrame, useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from '@react-three/drei';
import CameraPositionLogger from './positionLogger';
import "../App.css"

const ModelViewer = ({ metaData }) => {
  const { name, url, camPos, enableRotation, defaultRotation } = metaData;

  const [pos] = useState(camPos);

  return ( 
    <>
      <button className='nameButton'>{name}</button>

      <Canvas
        style={{ height: '80vh', width:"100vw" }}
        camera={{
          fov: 90,
          near: 1,
          far: 1000,
          position: pos,
        }}
        id="myCanvas"
      >
        <CameraPositionLogger event="mousedown" />
        <ambientLight intensity={Math.PI / 2} />
        <spotLight
          position={[10, 10, 10]}
          angle={0.15}
          penumbra={1}
          decay={0}
          intensity={Math.PI}
        />
        <pointLight position={[-10, -10, -10]} decay={0} intensity={Math.PI} />
        <Model
          url={url}
          enableRotation={enableRotation}
          defaultRotation={defaultRotation}
        />
        <OrbitControls
          enabled={true} // the controls can be disabled by setting this to false
          global={false} // Spin globally or by dragging the model
          cursor={true} // Whether to toggle cursor style on drag
          snap={false} // Snap-back to center (can also be a spring config)
          speed={1} // Speed factor
          zoom={1} // Zoom factor when half the polar-max is reached
          rotation={[3, 3, 5]} // Default rotation
          polar={[0, Math.PI / 2]} // Vertical limits
          azimuth={[-Infinity, Infinity]} // Horizontal limits
          config={{ mass: 1, tension: 170, friction: 26 }} // Spring config
        />
      </Canvas>
    </>
  );
};

const Model = ({ url, defaultRotation, enableRotation }) => {
  const [rotation, setRotation] = useState(defaultRotation && enableRotation);

  const model = useRef();
  const gltf = useLoader(GLTFLoader, url);

  const handleClick = () => {
    if (enableRotation) {
      setRotation(false);
    }
  };

  useFrame(() => {
    if (model.current) {
      model.current.rotation.y += rotation ? 0.01 : 0;
    }
  });

  return (
    <group
      ref={model}
      onClick={handleClick}
      rotation={[0, true ? Math.PI / 2 : 0, 0]}
    >
      <primitive object={gltf.scene} />
    </group>
  );
};

export default ModelViewer;
