import './modelPage.css';
import React from 'react';
import ModelViewer from './Components/modelViewer';

export function ModelPage() {
  const modelInfo = [
    // {
    //   id: 1,
    //   name : "demo 1",
    //   url : "models/bread.glb",
    //   camPos : [  0.31, 1.56, -0.2],
    //   enableRotation : true,
    //   defaultRotation : true,
    // },
    // {
    //   id: 2,
    //   name: 'Bread with Peanut butter',
    //   url: 'models/plate.glb',
    //   // camPos : [31.9, 117.55, 1.73],
    //   camPos: [1.17, 106.03, -38.65],
    //   enableRotation: true,
    //   defaultRotation: true,
    // },
    {
      id: 3,
      name: 'Mango Milkshake',
      url: 'models/MangoSmootie1.glb',
      // camPos : [31.9, 117.55, 1.73],
      // camPos: [31.9, 106.03, -38.65],
      enableRotation: true,
      defaultRotation: true,
    },
  ];

  return (
    <div className="App">
      
      {modelInfo.map((oneModel) => {
        return (
          // <div  key={oneModel.id}  style={{ height: "200px", width : "200px"}}>
          <ModelViewer key={oneModel.id} metaData={oneModel} />
          // </div>
        );
      })}

      {/* <ModelViewer url="models/plate.glb" /> */}
      {/* <hr /> */}
      {/* <ModelViewer url="models/bread.glb" /> */}
    </div>
  );
}

export default ModelPage;
