import React, { useEffect, useState } from "react";
import './ViewBlog.css'
import { useParams } from 'react-router-dom';

import { getBlogInfo } from "./BlogService";


const ViewBlog = ({ blogs }) => {
    const { id } = useParams();
    const [blogdata, setBlogData] = useState([]);

    // Now you can use the id to fetch the blog data or do anything else
    // For example, you can console log it to see its value
    useEffect(() => {
        console.log(id);
        getBlogInfo(id)
            .then((data) => {
                console.log(data);
                setBlogData(data['data'][0]);
            })
            .catch((error) => {
                // alert("sometihing went wrong..!");
            });
    },[]);

    return (
        <>
            <div className="announcement-container1">
                <img src="https://www.foodiesfeed.com/wp-content/uploads/2023/06/burger-with-melted-cheese.jpg" alt="Announcement" className="announcement-image1" />
                <div className="announcement-text1">
                    <div className="announcement-label1">{blogdata['title']}</div>
                    <div className="announcement-date1">{blogdata['updatedAt']}</div>
                    <div className="announcement-heading1">
                        This is the preliminary version of the platform; to receive further updates, please subscribe to this news room.
                    </div>
                    <div className="announcement-subheading" dangerouslySetInnerHTML={{ __html: blogdata['blogContent'] }}>
                        
                    </div>
                </div>
            </div>
            
        </>
    );
}

export default ViewBlog;