import { deleteRecord, getData, postData, putData } from "../../fetchCalls";
const moduleRoute = "/blog";

export const getAllBlogInfo = () => {
    return new Promise((resolve, reject) => {
        getData(`${moduleRoute}/`)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

export const getBlogInfo = (id) => {
    return new Promise((resolve, reject) => {
        getData(`${moduleRoute}/${id}`)
            .then((data) => {
                resolve(data);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

