import React, { useEffect, useState } from "react";
import './ListOfBlogs.css';

import { getAllBlogInfo } from "./BlogService";
import { Link } from 'react-router-dom';


const ListOfBlogs = ({ blogs }) => {
    const [blogdata, setBlogData] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(true);

    useEffect(() => {
        getDataFromAPI();
    }, []);

    const getDataFromAPI = () => {
        console.log("Inside getDataFromAPI  ")
        getAllBlogInfo()
            .then((data) => {
                if (Object.keys(data.data).length > 0) {
                    let tableData = data.data;
                    setBlogData(tableData);
                    console.log("tableData", tableData);
                    setIsDataLoaded(false);
                }
            })
            .catch((error) => {
                // alert("sometihing went wrong..!");

            });
    };


    return (
        <>

            {/* Change this code to display the data from the API call */}



            {blogdata.map((blog, index) => (
                <Link to={`/blog/${blog._id}`} key={index}>
                    <div className="announcement-container">
                        <img src={blog.image} alt="Announcement" className="announcement-image" />
                        <div className="announcement-text">
                            <div className="announcement-label">Announcement</div>
                            <div className="announcement-date">{blog.updatedAt}</div>
                            <div className="announcement-heading">
                                {blog.title}
                            </div>
                            <div className="announcement-subheading1" dangerouslySetInnerHTML={{ __html: blog['blogContent'] }}>
                            </div>
                        </div>
                    </div>
                </Link>
            ))}





        </>
    );
}

export default ListOfBlogs;